import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  Button,
  Heading,
  Text,
  Flex,
  theme
} from '@chakra-ui/core';
import { Global, css } from '@emotion/core';

function getUrlParameter(name) {
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(typeof window !== 'undefined' && window && window.location ? window.location.search : '');
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const PrivacyPolicy = () => {
  const token = typeof window !== undefined ? getUrlParameter('token') : null;
  const [{ loading, success }, setState] = useState({
    loading: true,
    success: null
  });

  useEffect(() => {
    (async () => {
      if (!token || success) {
        setState({ loading: false, success: false });
        return;
      }
      try {
        setState({ loading: true, success: false });

        const response = await fetch('/api/opt-out', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        });
        const data = await response.json();

        if (data.success) {
          setState({ loading: false, success: true });
        } else {
          setState({ loading: false, success: false });
        }
      } catch (err) {
        console.error(err);
        setState({ loading: false, success: false });
      }
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          body {
            font-family: 'sofia-pro', sans-serif;
          }
        `}
      />
      <Flex as="main" direction="column" m="auto" maxWidth="480px">
        <Heading m={0} mt={8}>
          Ta bort namnunderskrift
        </Heading>
        {loading ? (
          <Text my={4}>Laddar...</Text>
        ) : !token || success == null ? (
          <Text my={4}>Ogiltig länk</Text>
        ) : success ? (
          <Text my={4}>
            Din underskift är raderad. Om du ångrar dig så kan du trycka på
            knappen nedan för att skriva under igen.
          </Text>
        ) : (
          <Text my={4}>
            Vi kunde inte ta bort din underskrift på grund av ett oväntat fel
          </Text>
        )}

        <Button as="a" href="/" variantColor="red">
          Gå tillbaka
        </Button>
      </Flex>
    </ThemeProvider>
  );
};
export default PrivacyPolicy;
